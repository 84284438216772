.header-icon-btn {
  @apply w-8 h-8 opacity-75 bg-center bg-no-repeat ml-2;
  background-size: 1.25rem;

  &:hover {
    @apply opacity-100;
    filter: invert(54%) sepia(97%) saturate(435%) hue-rotate(194deg) brightness(99%) contrast(95%);
  }

  &.rss {
    background-image: url("../images/rss.svg");
  }

  &.github {
    background-image: url("../images/github.svg");
  }

  &.twitter {
    background-image: url("../images/twitter.svg");
  }
}
